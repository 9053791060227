import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="subject-form"
export default class extends Controller {
  static targets = ['previousButton', 'step', 'nameField', 'name', 'errorMessage'];
  static values = { step: Number, numPhotos: Number, minPhotos: Number, confirmPhotosUrl: String };

  connect() {
    this.maxSteps = this.stepTargets.length;
  }

  stepValueChanged() {
    this.toggleStep();
    this.updateName();
    this.hideErrorMessages();
  }

  toggleStep() {
    this.stepTargets.forEach((element) => {
      if (parseInt(element.dataset.step) != this.stepValue) {
        element.classList.add('hidden');
      } else {
        element.classList.remove('hidden');
      }
    });
  }

  updateName() {
    const name = this.nameFieldTarget.value || 'your pet';
    this.nameTargets.forEach((element) => (element.innerText = name));
  }

  typeChange() {
    this.errorMessageTarget.classList.add('hidden');
  }

  hideErrorMessages() {
    this.errorMessageTargets.forEach((element) => element.classList.add('hidden'));
  }

  handleHotkey(selector) {
    this.stepTargets.forEach((element) => {
      if (!element.classList.contains('hidden')) {
        element.querySelector(selector)?.click();
      }
    });
  }

  handleHotkeyPrevious() {
    this.handleHotkey('.subjectForm--previousBtn');
  }

  handleHotkeyNext() {
    this.handleHotkey('.subjectForm--nextBtn');
  }

  validateType(event) {
    const stepDiv = event.target.closest('div[data-step]');
    if (stepDiv.querySelectorAll('input:checked').length > 0) return;

    this.showErrorMessage(event, stepDiv);
  }

  validatePhotos(event) {
    const stepDiv = event.target.closest('div[data-step]');
    if (this.numPhotosValue >= this.minPhotosValue) return;

    this.showErrorMessage(event, stepDiv);
  }

  showErrorMessage(event, stepDiv) {
    stepDiv.querySelector('.subjectForm--errorMessage').classList.remove('hidden');
    event.stopImmediatePropagation();
  }

  // Dispatched from multiple-upload controller.
  handlePhotoCountChanged(numPhotos) {
    this.numPhotosValue = numPhotos;
    this.hideErrorMessages();
  }

  finalCheck() {
    document.querySelector(
      '#modal',
    ).src = `${this.confirmPhotosUrlValue}?name=${this.nameFieldTarget.value}`;
  }

  previousStep = () => (this.stepValue = Math.max(1, this.stepValue - 1));

  nextStep = () => (this.stepValue = Math.min(this.maxSteps, this.stepValue + 1));
}
