import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="prompt-ratings-form"
export default class extends Controller {
  static values = { promptId: Number };

  hideStyle() {
    document.getElementById(`gallery_pick_favorites_prompt_${this.promptIdValue}`).remove();
  }

  unhideStyle() {
    document.getElementById(`gallery_hidden_prompt_${this.promptIdValue}`).remove();
  }
}
