import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="style-packs-index"
export default class extends Controller {
  static targets = ['stylePack'];
  static values = { sortByByType: Object };

  sort(event) {
    const sortBy =
      this.sortByByTypeValue[event.target.value] || this.sortByByTypeValue['popularity'];
    this.stylePackTargets.forEach((target) => {
      target.style.order = sortBy[target.dataset.stylePackId];
    });
  }
}
