import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="product-image"
export default class extends Controller {
  static targets = ["spinner", "loadingImage"]

  updateImageSrc(image, url) {
    image.src = url;
    image.onload = () => {
      // Remove spinner only after image is loaded to avoid flickering.
      this.spinnerTarget.classList.add('hidden');
    };
  }

  loadingImageTargetConnected(image) {
    let counter = 0;

    const pollImage = setInterval(() => {
      // We only need to call the ensure_image...! methods the first time.
      // The subsequent requests only need to check when the image is ready.
      fetch(counter++ == 1 ? image.dataset.url + "&ensure_image=true" : image.dataset.url)
        .then((response) => {
          response.json().then((data) => {
            if (!data.url) return;

            clearInterval(pollImage);
            this.updateImageSrc(image, data.url);
          });
        }).catch(() => {
          clearInterval(pollImage)
          alert("Something went wrong. Please contact help@pugmug.ai for assistance.")
        });
    }, 1000)
  }
}
