import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="gallery"
export default class extends Controller {
  static targets = ['disclosure', 'styleRow', 'favorite', 'carouselEndSpacer', 'favoritesCopy'];
  static values = { minNumFavorites: Number, favoritesDoneCopy: String };

  connect() {
    window.addEventListener('load', this.scrollToStyle(window.location.href));
  }

  disconnect() {
    window.removeEventListener('load', this.scrollToStyle(window.location.href));
  }

  interceptScrollToStyle(event) {
    event.preventDefault();
    this.scrollToStyle(event.target.href);
  }

  // Scroll to prompt if there is a [parameterized] prompt style in the URL query
  // Using anchor directly doesn't work well because we have a sticky header
  scrollToStyle(url) {
    const style = new URL(url).searchParams.get('style');
    let element = document.getElementById(style);
    if (!element) {
      const stylePack = new URL(url).searchParams.get('style_pack');
      element = document.querySelector(`[data-style-pack="${stylePack}"]`);
    }
    if (!element) return;
    // Toggle open hidden group if the style is in the hidden group
    const parent = element.closest('.contentToggle');
    if (!parent.hasAttribute('open')) parent.setAttribute('open', true);
    const headerOffset = document.getElementById('gallery-header').getBoundingClientRect().height;
    const elementPosition = element.getBoundingClientRect().top;
    window.scrollTo(0, elementPosition + window.scrollY - headerOffset - 12);
  }

  // Show/hide each of the details disclosure elements based on whether there are any style rows.
  styleRowTargetConnected() {
    this.disclosureTargets.forEach((element) => {
      if (element.querySelector('.galleryGroup:empty')) {
        element.classList.add('hidden');
      } else {
        element.classList.remove('hidden');
      }
    });
  }

  addToFavoritesHeader(event) {
    if (this.removeFromFavoritesHeader(event)) return;

    let added = false;
    let img = document.createElement('img');
    img.setAttribute('data-image-id', event.params.imageId || event.detail.imageId);
    img.src = event.params.imageUrl || event.detail.imageUrl;

    // Fill in the empty favorite slots first.
    this.favoriteTargets.forEach((element) => {
      if (!added && element.childNodes.length == 0) {
        element.appendChild(img);
        added = true;
        this.updateFavoritesHeaderCopy();
      }
    });

    if (added) return;

    // If there are no empty slots, add new image.
    let item = document.createElement('div');
    item.className = 'galleryHeader--favorite';
    item.setAttribute('data-gallery-target', 'favorite');
    item.appendChild(img);

    this.favoriteTargets[this.favoriteTargets.length - 1].after(item);
    this.carouselEndSpacerTarget.scrollIntoView({
      behavior: 'smooth',
      block: 'nearest',
      inline: 'start',
    });
    this.updateFavoritesHeaderCopy();
  }

  removeFromFavoritesHeader(event) {
    const existingImage = document.querySelector(
      `.galleryHeader--favorite img[data-image-id='${
        event.params.imageId || event.detail.imageId
      }']`,
    );
    if (existingImage) {
      existingImage.parentElement.remove();
      // If less than this.minNumFavoritesValue favorites, add an empty div as image placeholders.
      if (this.favoriteTargets.length < this.minNumFavoritesValue) {
        const emptyDiv = document.createElement('div');
        emptyDiv.setAttribute('data-gallery-target', 'favorite');
        emptyDiv.className = 'galleryHeader--favorite';
        this.favoriteTargets[this.favoriteTargets.length - 1].after(emptyDiv);
      }
      this.updateFavoritesHeaderCopy();
      return true;
    } else {
      return false;
    }
  }

  updateFavoritesHeaderCopy() {
    if (!this.hasFavoritesCopyTarget) return;

    if (document.querySelector('.galleryHeader--favorite:empty')) {
      this.favoritesCopyTarget.textContent = `Pick at least ${this.minNumFavoritesValue} favorites!`;
    } else {
      this.favoritesCopyTarget.textContent = this.favoritesDoneCopyValue;
    }
  }
}
