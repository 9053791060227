import { Controller } from '@hotwired/stimulus';

// Google Conversion Events: https://ads.google.com/aw/conversions?ocid=922958115&ascid=922958115&euid=629222768&__u=6770370032&uscid=922958115&__c=2446514635&authuser=0
// Twitter Conversion Events: https://ads.twitter.com/conversion_events/18ce55ko7ym/events_manager/ohf6l/overview
export default class extends Controller {
  static values = {
      event: String,
      totalPrice: String,
      transactionId: String,
  }

  connect() {
    if (this.eventValue === "order") {
        this.order_gtag();
        this.order_twq();
        this.order_rdt();
    } else if (this.eventValue === "style_pack_purchase") {
        this.style_pack_purchase_gtag();
        this.style_pack_purchase_twq();
        this.style_pack_purchase_rdt();
    } else {
        // console.error("Invalid event value");
    }
  }

  style_pack_purchase_gtag() {
    if (typeof gtag === 'undefined') {
      return;
    }
    const payload = {
        'send_to': 'AW-10907505978/liedCMDXs_YYELqqjdEo',
        'value': this.totalPriceValue,
        'currency': 'USD',
        'transaction_id': this.transactionIdValue,
    };
    // console.log(payload);
    gtag('event', 'conversion', payload);
  }

  style_pack_purchase_twq() {
    if (typeof twq === 'undefined') {
      return;
    }
    const payload = {
      value: this.totalPriceValue,
      currency: 'USD',
      conversion_id: this.transactionIdValue,
    };
    // console.log(payload);
    twq('event', 'tw-ohf6l-ohf6r', payload);
  }

  order_gtag() {
    if (typeof gtag === 'undefined') {
      return;
    }
    const payload = {
        'send_to': 'AW-10907505978/yAK7CMXSr_YYELqqjdEo',
        'value': this.totalPriceValue,
        'currency': 'USD',
        'transaction_id': this.transactionIdValue,
    };
    // console.log(payload);
    gtag('event', 'conversion', payload);
  }

  order_twq() {
    if (typeof twq === 'undefined') {
      return;
    }
    const payload = {
      value: this.totalPriceValue,
      currency: 'USD',
      conversion_id: this.transactionIdValue,
    };
    // console.log(payload);
    twq('event', 'tw-ohf6l-ohf70', payload);
  }

  style_pack_purchase_rdt() {
    if (typeof rdt === 'undefined') {
      return;
    }

    const payload = {
      customEventName: "StylePackPurchase",
      value: this.totalPriceValue,
      currency: 'USD',
      transactionId: this.transactionIdValue,
    };

    rdt('track', 'Custom', payload);
  }

  order_rdt() {
    if (typeof rdt === 'undefined') {
      return;
    }

    const payload = {
      customEventName: "OrderItems",
      value: this.totalPriceValue,
      currency: 'USD',
      transactionId: this.transactionIdValue,
    };

    rdt('track', 'Custom', payload);
  }
}
