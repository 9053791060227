import { Controller } from '@hotwired/stimulus';

// Connects to data-controller="turbo-modal"
// https://www.bearer.com/blog/how-to-build-modals-with-hotwire-turbo-frames-stimulusjs
export default class extends Controller {
  static targets = ['content', 'checkFieldEmptyBeforeModalClose'];

  connect() {
    // Store original URL so we can update browser history when closing the modal.
    const originalUrl = new URL(window.location.href);
    window.originalUrl = this.originalUrl = originalUrl.origin + originalUrl.pathname;

    this.handleFrameSrc();
    document.addEventListener('turbo:click', this.handleTurboClick);
    window.addEventListener('popstate', this.handlePopState);
  }

  disconnect() {
    document.removeEventListener('turbo:click', this.handleTurboClick);
    window.removeEventListener('popstate', this.handlePopState);
  }

  // If page has frame_src param, open modal by setting frame src to that value.
  handleFrameSrc = () => {
    const url = new URL(window.location.href);
    const frameSrc = url.searchParams.get('frame_src');
    if (frameSrc) {
      window.history.replaceState({}, '', this.originalUrl);
      this.element.src = frameSrc;
      this.handleOpenModal(frameSrc);
    }
  };

  // When turbo link is clicked and the target is the modal
  handleTurboClick = (event) => {
    if (event.target.closest('#modal') && event.target.dataset.turboFrame == '_top') {
      // If clicking within modal to leave modal
      this.hideModal(event);
    } else if (event.target.closest('#modal') || event.target.dataset.turboFrame == 'modal') {
      // If clicking within modal or to open modal
      this.handleOpenModal(event.detail.url);
    }
  };

  // When navigating with browser buttons.
  handlePopState = (event) => {
    if (event.state && event.state.parentUrl) {
      Turbo.visit(event.state.parentUrl, { action: 'replace' });
    } else if (event.state && event.state.modalSrc) {
      this.element.src = event.state.modalSrc;
    } else {
      this.hideModal(event);
    }
  };

  handleOpenModal(src) {
    document.body.style.overflow = 'hidden';
    window.history.pushState({ modalSrc: src }, '', src);
  }

  hideModal(event) {
    if (this.element.innerHTML === '') return;

    // Verifies non-empty fields and asks for user confirmation before closing modal.
    const confirmClose = this.checkFieldEmptyBeforeModalCloseTargets.some(
      (target) => target.value.length > 0,
    );
    const CLOSE_MODAL_CONFIRMATION = 'You have unsaved changes. Are you sure you want to close?';
    if (confirmClose && !confirm(CLOSE_MODAL_CONFIRMATION)) return;

    this.element.removeAttribute('src');
    this.element.innerHTML = '';
    document.body.style.overflow = '';

    // If navigating by browser buttons, don't push any history state.
    if (event.type == 'popstate') return;

    // Update history state so can later return to parent url, and update history so browser url is
    // not the modal src.
    window.history.pushState({ parentUrl: this.originalUrl }, '', this.originalUrl);
  }

  // hide modal when clicking ESC
  // action: "keyup.esc@window->turbo-modal#closeWithKeyboard"
  closeWithKeyboard = (e) => this.hideModal(e);

  // hide modal when clicking outside of modal
  // action: "click@window->turbo-modal#closeBackground"
  closeBackground(e) {
    if (!e) return;
    const content = this.contentTargets.some((contentTarget) => contentTarget.contains(e.target));
    if (content) return;
    this.hideModal(e);
  }
}
