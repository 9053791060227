import { Controller } from "@hotwired/stimulus"

// Connects to data-controller="subject-style-packs-index"
export default class extends Controller {
  static targets = ['lineItems']

  lineItemsTargetConnected() {
    this.lineItemsTarget.scrollTop = this.lineItemsTarget.scrollHeight;
  }
}
